<template>
  <div class="Home">
    <!-- <h2 class="Home_Alert">{{service ? "Selecciona por favor un puesto de trabajo" : "Selecciona por favor un servicio"}}</h2> -->
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      timer: null,
      service: null,
    };
  },
  created() {
    this.restartTimer();
    this.$root.enableChangeWorkStation = true;
    if (!this.getThemeMode.verticalSidebarDrawer) {
      this.changeVerticalSidebarDrawer(true);
    }
    // Check Service Selected
    this.service = this.$route.query?.service;
  },
  destroyed() {
    clearInterval(this.timer);
  },
  watch: {
    selectedBranch(val) {
      if (val) {
        if (!this.getThemeMode.verticalSidebarDrawer) {
          this.changeVerticalSidebarDrawer(true);
        }
      }
    },
    "$route.query"(val) {
      if (val) this.service = val.service;
    },
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapState({
      selectedBranch: (state) => state.branchesSilver.selectedBranch,
      branchesData: (state) => state.branchesSilver.branchesData,
    }),
  },
  methods: {
    ...mapActions(["getCompanyBranchesInfo", "changeVerticalSidebarDrawer"]),
    restartTimer() {
      this.timer = setInterval(() => {
        this.getCompanyBranchesInfo(
          this.selectedBranch?.id ||
            (this.branchesData.defaultBranch
              ? this.branchesData.defaultBranch?.id
              : this.branchesData[0]?.id)
        );
      }, 3000);
    },
  },
};
</script>

<style scoped>
.Home {
  padding: 1rem;
}

.Home_Alert {
  font-size: 1.5rem;
  font-weight: 600;
}
</style>
